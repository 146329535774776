import * as React from "react"
import Helmet from "react-helmet"
import {Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Row from "../../../components/row"

const CurrentResearch = () => (
  <Layout>
	<Helmet>
        <script src={'/js/main.js'} type="text/javascript" />
	    <script src={'/js/current-research.js'} type="text/javascript" />
    </Helmet>
    <Seo title="Current Research in IBD" description="Select reference articles in IBD, including research into the role of S1P metabolism" />
    <header className="headline">
	<img src={"/images/black-and-green-ibd-science-graphic-image.svg"} alt="ibd science gastroenterology" />
	</header>
	<section className="wrap"><h2 className="perfectSpace">Curated and continually updated content to advance the science and treatment of IBD</h2><br/>
	<header className="orange rule"><h1 id="current-research">Current research in <span>IBD</span></h1></header>
		<p className="no-top-on-mobile"> Select reference articles in IBD, including research into the role of sphingosine-1-phosphate (S1P) metabolism</p>

		<div className="full inline"><div className="tabHolder"><div className="tab selected"><a href="#tab0">Inflammatory Bowel Disease</a></div><div className="tab"><a href="#tab1">Ulcerative Colitis</a></div><div className="tab"><a href="#tab2">Healthcare Professional
and Patient Perspectives in IBD</a></div><div className="tab"><a href="#tab3">Sphingosine-1-Phosphate</a></div></div></div>
	<div className="full inline"><section className="tabContent">
	  <div className="content rows selected">
		 <Row  
				title="Novel and emerging therapies for inflammatory bowel disease"
				author={<p>Al-Bawardy B, Shivashankar R, Proctor DD. <em>Front Pharmacol</em>. 2021;12:651415. </p>} 
                link="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8080036/"
                description="Reviews novel treatment agents currently in development."
			/>

<Row  
			title="A comprehensive review and update on the pathogenesis of inflammatory bowel disease "
			author={<p>Guan Q. <em>J Immunol Res. </em> 2019;2019:7247238.</p>} 
			link="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6914932/"
			description="Reviews recent advances for development of new IBD therapies."
			/>

<Row  
				title="A review of the diagnosis, prevention, and treatment methods of inflammatory bowel disease"
				author={<p>Seyedian SS, Nokhostin F, Malamir MD. <em>J Med Life. </em>2019;12(2):113-122.</p>} 
                link="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6685307/"
                description="Reviews the pathophysiology of IBD, including Crohn’s disease and ulcerative colitis."
			/>

<Row  
				title="Mechanism-based treatment strategies for IBD: cytokines, cell adhesion molecules, JAK inhibitors, gut flora, and more"
				author={<p>Schreiner P, Neurath MF, Ng SC, et al. <em>Inflamm Intest Dis. </em>2019;4(3)79-76.</p>} 
                link="https://pubmed.ncbi.nlm.nih.gov/31559260/"
                description="A review of the inflammatory signaling pathways in IBD and current and future therapeutic approaches to inhibiting them."
			/>

<Row  
				title="Focus on current and future management possibilities in inflammatory bowel disease–related chronic pain"
				author={<p>Zielińska A, Sałaga M, Włodarczyk M, Fichna J. <em>Int J Colorectal Dis. </em>2019;34(2):217-227.</p>} 
                link="https://pubmed.ncbi.nlm.nih.gov/30564910/"
                description="Describes the relationship between IBD and the development of visceral chronic pain and its effects on patient quality of life."
			/>

<Row  
				title="Current perspectives on the diagnosis and management of functional anorectal disorders in patients with inflammatory bowel disease"
				author={<p>Nigam GB, Limdi JK, Vasant DH. <em>Therap Adv Gastroenterol. </em>2018;11:1756284818816956.</p>} 
                link="https://pubmed.ncbi.nlm.nih.gov/30574193/"
                description="Reviews the current perspective on diagnosis and management of anorectal sensitivity, motility, and neuromuscular coordination in patients with IBD."
			/>

<Row  
				title="Targets for health interventions for inflammatory bowel disease–fatigue"
				author={<p>Artom M, Czuber-Dochan W, Sturt J, Norton C. <em>J Crohns Colitis. </em>2016;10(7):860-869.</p>} 
                link="https://pubmed.ncbi.nlm.nih.gov/26802088/"
                description="Explores the potentially modifiable factors contributing to IBD fatigue and describes advances in fatigue management in patients with IBD."
			/>
	</div><div className="content rows">
	<Row  
				title="Ulcerative colitis: an update"
				author={<p>Segal J, LeBlanc JF, Hart AL. <em>Clin Med (Lond). </em>2021;21(2):135-139.</p>} 
                link="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8002778/"
                description="Discusses currently available therapeutic options and the potential for personalized treatment."
			/>

<Row  
				title="Ulcerative colitis: recent advances in the understanding of disease pathogenesis"
				author={<p>Porter JR, Kalla R, Ho GT. <em>F1000Res. </em>2020;9:F1000 Faculty Rev-294.</p>} 
                link="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7194476/"
                description="Reviews novel developments in understanding the pathogenesis of UC."
			/>	
			
<Row  
				title="Ulcerative colitis: understanding its cellular pathology could provide insights into novel therapies"
				author={<p>Kaur A, Goggolidou P. <em>J Inflamm (Lond). </em>2020;17:15.</p>} 
                link="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7175540/"
                description="Reviews the pathophysiology of ulcerative colitis to identify potential targets for therapy and help manage the condition."
			/>
</div>	

<div className="content rows">
	<Row  
				title="International perspectives on management of inflammatory bowel disease: opinion differences and similarities between patients and physicians from the IBD GAPPS survey"
				author={<p>Rubin DT, Sninsky C, Siegmund B, et al. <em>Inflamm Bowel Dis. </em>2021;27(12):1942-1953.</p>} 
                link="https://pubmed.ncbi.nlm.nih.gov/33512475"
                description="Discusses the results of a survey of over 2300 patients with IBD and 650 physicians regarding education about disease remission and expectations for current therapies."
			/>

<Row  
				title="A qualitative research for defining meaningful attributes for the treatment of inflammatory bowel disease from the patient perspective "
				author={<p>Louis E, Ramos-Goñi JM, Cuervo J, et al. <em>Patient. </em>2020;13(3):317-325.</p>} 
                link="https://pubmed.ncbi.nlm.nih.gov/31997116/"
                description="Analyzes the ten most relevant attributes that influence patient decisions when making treatment choices for IBD."
			/>

<Row  
				title="Patients perceive biologics to be riskier and more dreadful than other IBD medications"
				author={<p>Koliani-Pace JL, Haron AM, Zisman-Ilani Y, Thompson KD, Siegel CA. <em>Inflamm Bowel Dis.</em> 2020;26(1):141-146.</p>} 
                link="https://pubmed.ncbi.nlm.nih.gov/31300811/"
                description="A cross-sectional study at a single tertiary care center reveals patient perceptions of biologic therapies for IBD."
			/>
			
<Row  
				title="Patient and physician preferences for ulcerative colitis treatments in the United States"
				author={<p>Boeri M, Myers K, Ervin C, et al. <em>Clin Exp Gastroenterol. </em>2019;12:263-278.</p>} 
                link="https://pubmed.ncbi.nlm.nih.gov/31354328/"
                description="Identifies the treatment attributes that both patients and physicians consider most important when making decisions on UC treatment."
			/>

<Row  
				title="National differences in ulcerative colitis experience and management among patients from five European countries and Canada: an online survey"
				author={<p>Schreiber S, Panés J, Louis E, Holley D, Buch M, Paridaens K. <em>J Crohns Colitis. </em>2013;7(6):497-509.</p>} 
                link="https://pubmed.ncbi.nlm.nih.gov/22921464/"
                description="Reports results from an international survey of patients with UC and health care professionals involved in the care of patients with UC to explore existing gaps in the management of UC."
			/>
</div>	

<div className="content rows">
	<Row  
				title="Targeting sphingosine-1-phosphate signaling in immune-mediated diseases: beyond multiple sclerosis"
				author={<p>Pérez-Jeldres T, Alvarez-Lobos M, Rivera-Nieves J. <em>Drugs. </em>2021;81(9):985-1002.</p>} 
                link="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8116828/"
                description="Evaluates S1P modulators as a therapeutic strategy for immune-mediated diseases."
			/>
</div>	



</section></div></section>
    <section className="spacer end"></section>
	<footer className="definitions">ECCO=European Crohn’s and Colitis Organisation; GAPPS=Global Assessment of Patient and Physician Unmet Needs Survey; IBD=inflammatory bowel disease; JAK=Janus kinase; UC=ulcerative colitis.</footer>
  </Layout>
)

export default CurrentResearch
