import * as React from "react"

const Row = ( {title, author, link, description} ) => {
  return (
	 <>
     <a href={link} target="_blank" rel="noreferrer">
      <h5>{title}</h5>
		<>{author}</>
		<div><hr/><img src={"/images/view-button.svg"} alt="View" width="170" height="35" /></div>
		<span>{description}</span>
	  </a>
	  </>
  )
}


export default Row
